export default {
  appLogo: "https://customer.io/wp-content/uploads/2019/01/customer-io-logo-color.svg",
  appName: "customerio",
  data: {
    apiKey: "",
    siteId: ""
  },
  segments: [],
  lastDelivery: 0,
  type: "send",
  // Added later on:
  // lastUpdated: Number secSinceEpoc,
  // _created: Number secSinceEpoc,
  // archived: Boolean 
}
