import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, Switch, Route, Redirect, useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  ListGroup,
  ListGroupItem,
	Modal,
	Form,
	InputGroup
} from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { PersonPlus } from "react-bootstrap-icons";
import { db, getDoc, getDocs, firebase } from "./firebase";
import { removeItemAll } from "./interfaceListShackPro";

const ShareButton = props => {
	const { selectedData, user} = props;

	const [showModal, setShowModal] = useState(false);
	const [publicLink, setPublicLink] = useState(false);
	const [emailForm, setEmailForm] = useState("")
	const [allowedEmails, setAllowedEmails] = useState([])

	const addEmail = e => {
		e.preventDefault();
		if (emailForm.length === 0) {
			return
		}
		setAllowedEmails([...allowedEmails, emailForm]);
		setEmailForm("");
	}

	return(
		<React.Fragment>
			<Button
				variant="outline-success"
				onClick={ () => {
					console.log("Clicked Add Share Link")
					setShowModal(true);
				}}
			>
				{props.children ? props.children : <PersonPlus size={30} />}
			</Button>
			{showModal &&
        <Modal show={showModal} onHide={() => setShowModal(false)}
					autoFocus={false}
				>
					<Modal.Header>
						<Modal.Title>Create Shareable Link</Modal.Title>
					</Modal.Header>
          <Modal.Body>
						<Form onSubmit={e => addEmail(e)}>
							<Form.Group>
								<Form.Label>Who would you like to be able to use this Shareable Link?</Form.Label>
								<Form.Check 
									type="switch"
									id="custom-switch"
									label="Anyone with the link"
									checked={publicLink}
									onChange={() => setPublicLink(!publicLink)}
								/>
							</Form.Group>
							{!publicLink &&
								<React.Fragment>
									<ListGroup>
										{allowedEmails.length > 0 &&
											allowedEmails.map( (email,i) => {
												return(
													<ListGroup.Item key={`email_${i}`} className="d-flex flex-row justify-content-between">
														{email} 
														<Button
															variant="link"
															style={{padding: "0px"}}
															onClick={() => {
																let newEmails = removeItemAll([...allowedEmails], email);
																//console.log("newEmails: ", newEmails);
																setAllowedEmails(newEmails);
															}}
														>
															&times;
														</Button>
													</ListGroup.Item>
												)
											})
										}
										{!allowedEmails.length &&
											<ListGroup.Item className="text-muted">jane@example.com</ListGroup.Item>
										}
									</ListGroup>
									<InputGroup>
										<Form.Control
											as="input"
											type="text"
											value={emailForm}
											onChange={ e => setEmailForm(e.target.value)}
											size="lg"
											placeholder="Type email address"
											autofocus="true"
										/>
										<InputGroup.Append>
											<Button variant="outline-secondary"
												onClick={ e => {
													addEmail(e)
												}}
												disabled={emailForm.length === 0}
											>Add</Button>
										</InputGroup.Append>
									</InputGroup>
								</React.Fragment>
							}
						</Form>
          </Modal.Body>
          <Modal.Footer className="justify-content-between">
            <Button size="sm"
              onClick={() => setShowModal(false)}
              variant="light"
            >
              Hide
            </Button>
						<Button size="md"
							variant="success"
							onClick={async () => {
								let now = new Date().getTime() / 1000;
								let nd = await db.collection("links").add({
									contentIds: selectedData,
									summaryIds: [],
									createdBy: user.uid,
									_created: now,
									lastUpdated: now,
									archived: false,
									public: publicLink,
									allowedEmails: []
								});
								console.log("nd: ", nd);
							}}
						>
							Create Link
						</Button>
          </Modal.Footer>
        </Modal>
      }
		</React.Fragment>
	)
}

const ShowSharedStuff = props => {
	const {contentDocs, summaryDocs} = props;
	return(
		<React.Fragment>
			<h5>Content</h5>
			<ListGroup>
				{contentDocs.map( doc => {
					return(
						<ListGroup.Item><a href={`/a/${doc._docId}`} rel="noopener noreferrer" target="_blank" >{doc.data.title ? doc.data.title : doc._docId}</a></ListGroup.Item>
					)
				})}
			</ListGroup>
		</React.Fragment>
	)
}

const SharePage = props => {
  const {user} = props;
  const [sharerDoc, setSharerDoc] = useState(null);
  const [shareLink, setShareLink] = useState(null);
  const [contentDocs, setContentDocs] = useState([]);

  const {linkid} = useParams();

  const loadPage = async () => {
    let aref = db.collection("links").doc(linkid);
    let linkdoc = await getDoc({ref: aref});
    let bref = db.collection("users").doc(linkdoc.createdBy);
    let creatorDoc = await getDoc({ref: bref});
    let cref = db.collection("content").where(firebase.firestore.FieldPath.documentId(), "in", linkdoc.contentIds);
    let cdocs = await getDocs({ref: cref})
    setSharerDoc(creatorDoc);
    setShareLink(linkdoc);
    setContentDocs(cdocs);
  }

  useEffect( () => {
    if (linkid) {
      loadPage();
    }
  }, [linkid]);

  if (!sharerDoc || !shareLink) {
    return null
  }

  return(
    <Container>
      <Row>
        <Col>
          <h1>{sharerDoc.userProfile.name} would like to share the following:</h1>
          <Card>
            <Card.Body>
              <ShowSharedStuff {...props} contentDocs={contentDocs} />
            </Card.Body>
            <Card.Footer>
              {user && 
                <Button>Add to My Account</Button>
              }
              {!user &&
                <Link to={`/login?next=${window.location.pathname}`}>Sign in to Add to Your Account</Link>
              }
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

const ShareRouter = props => {
  return (
    <Switch>
      <Route exact path={["/share/:linkid"]}>
        <SharePage {...props} />
      </Route>
      <Route exact path={["/share/"]} >
        <Redirect to="/share" />
      </Route>
    </Switch>
  );
}

export { ShareButton, ShareRouter };