import React from "react";
import { Route } from "react-router-dom";
import { Helmet } from "react-helmet";

import { Digests, ArticleRouter, Import, DigestRouter, Home, Privacy, Terms } from "./interfaceDigests.jsx";
import { ShareRouter } from "./shareDigest.jsx";
import Content from "./contentTable.jsx";
import cio from "./integration_customerio.jsx";
import sendgrid from "./integration_sendgrid.jsx";
import forumbee from "./integration_forumbee.jsx";
import headway from "./integration_headway";
import wordpress from "./integration_wordpress";
import webflow from "./integration_webflow";

const DigestRoutes = props => {
  let {loaded} = props;
  return(
    <React.Fragment>
      <Route exact path="/">
        <Helmet>
          <title>{process.env.REACT_APP_site_name}</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <Home {...props} />
      </Route>

      <Route exact path="/home">
        <Helmet>
          <title>{process.env.REACT_APP_site_name}</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <Home />
      </Route>

      <Route path="/pricing">
        <Helmet>
          <title>Pricing</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <div>Right now this app is free.</div>
      </Route>

      <Route path="/contact">
        <Helmet>
          <title>Contact Us</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <div>Right now no one is manning the desk for this app.</div>
      </Route>

      <Route path="/terms">
        <Helmet>
          <title>Terms of service</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <Terms />
      </Route>

      <Route path="/privacy">
        <Helmet>
          <title>Privacy policy</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <Privacy />
      </Route>

      <Route path="/support">
        <Helmet>
          <title>Support Center</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <div>No one is currently manning the support desk. Check back later.</div>
      </Route>

      {loaded &&
        <React.Fragment>
          <Route path="/smries">
            <Helmet>
              <title>Smries</title>
              <meta name="HandheldFriendly" content="true" />
              <meta name="apple-mobile-web-app-capable" content="YES" />
            </Helmet>
            <Digests {...props} />
          </Route>
          <Route path="/content">
            <Helmet>
              <title>Content</title>
              <meta name="HandheldFriendly" content="true" />
              <meta name="apple-mobile-web-app-capable" content="YES" />
            </Helmet>
            <Content {...props} />
          </Route>

          <Route path="/article">
            <Helmet>
              <title>Article</title>
              <meta name="HandheldFriendly" content="true" />
              <meta name="apple-mobile-web-app-capable" content="YES" />
            </Helmet>
            <ArticleRouter {...props} />
          </Route>
          <Route path="/a">
            <Helmet>
              <title>Article</title>
              <meta name="HandheldFriendly" content="true" />
              <meta name="apple-mobile-web-app-capable" content="YES" />
            </Helmet>
            <ArticleRouter {...props} viewOnly />
          </Route>

          <Route path="/share">
            <Helmet>
              <title>Share</title>
              <meta name="HandheldFriendly" content="true" />
              <meta name="apple-mobile-web-app-capable" content="YES" />
            </Helmet>
            <ShareRouter {...props} />
          </Route>

          <Route path="/import">
            <Helmet>
              <title>Import</title>
              <meta name="HandheldFriendly" content="true" />
              <meta name="apple-mobile-web-app-capable" content="YES" />
            </Helmet>
            <Import {...props}
              integrations={[forumbee, headway, wordpress, webflow]}
              type="import"
            />
          </Route>

          <Route path={["/smry", "/view"]}>
            <Helmet>
              <title>{process.env.REACT_APP_site_name}</title>
              <meta name="HandheldFriendly" content="true" />
              <meta name="apple-mobile-web-app-capable" content="YES" />
            </Helmet>
            <DigestRouter {...props} />
          </Route>

          <Route path="/send">
            <Helmet>
              <title>Send Content</title>
              <meta name="HandheldFriendly" content="true" />
              <meta name="apple-mobile-web-app-capable" content="YES" />
            </Helmet>
            <Import
              {...props}
              title="Send Content"
              type="send"
              integrations={[cio, sendgrid]}
            />
          </Route>

        </React.Fragment>
      }
    </React.Fragment>
  )
}

export default DigestRoutes;
