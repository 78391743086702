export default {
    appLogo: "/images/webflow-logo-black.svg",
    appName: "webflow",
    data: {
      apiToken: "",
      siteId: "",
      siteUrl: "",
      collections: [
        {
          collectionName: "",
          title: "",
          preview: "",
          image: "",
          content: "",
          link: ""
        }
      ]
    },
    importedContent: [],
    lastImport: 0,
    lastWebhook: 0,
    type: "import"
    // Added later on:
    // lastUpdated: Number secSinceEpoc,
    // _created: Number secSinceEpoc,
    // archived: Boolean 
  }
  