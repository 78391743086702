import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  ListGroup,
  ListGroupItem
} from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SelectedParams from "./selectedParams.jsx";
import LeadsCount from "./leadscount.jsx";
import { NoPage } from "./interfaceListShackPro.jsx";
import { setSavedSearch } from "./savedSearch.jsx";

//To Do: This is a public page where you can view a saved search without being logged in
//To Do: Change database permissions to allow public read access to saved searches collection

const ShareSearch = props => {
  const { user, handleIsApp, handleDistract, dicts, handleAlerts } = props;
  const [geographyParams, setGeographyParams] = useState({});
  const [searchParams, setSearchParams] = useState({});
  const [searchName, setSearchName] = useState("Saved Search");
  const [search, setSearch] = useState(null);
  const [dict, setDict] = useState({});
  const [loaded, setLoaded] = useState(false);
  //console.log("ShareSearch props: ", props);
  useEffect(() => {
    if (user) {
      handleIsApp(true);
    } else {
      handleIsApp(false);
      handleDistract(true);
    }

    async function setShareSearchState() {
      await setSavedSearch(
        {
          handleState: result => {
            //console.log("ran handle state!");
            setGeographyParams(result.geographyParams);
            setSearchParams(result.searchParams);
            setSearchName(result.savedSearch.searchName);
            setDict(result.dataDict);
            setSearch(result.savedSearch);
            setLoaded(true);
          },
          dicts
        },
        (error, result) => {
          //console.log(error, result);
          if (error) {
            setSearch(false);
            setLoaded(true);
          }
        }
      );
    }

    setShareSearchState();
  }, [dicts, handleDistract, handleIsApp, user]);

  if (loaded) {
    //console.log("Shared search: ", search, typeof search.count !== "undefined");
    if (!search) {
      return <NoPage />;
    }
    //console.log("dict: ", dict);
    const showLeadsCount =
      typeof search.count !== "undefined"
        ? true
        : typeof search.downloadCount !== "undefined"
        ? true
        : false;
    return (
      <Container>
        <Row
          style={{ minHeight: "70vh", textAlign: "center", marginTop: "40px" }}
        >
          <Col>
            <h1>{process.env.REACT_APP_site_name} Shared Search</h1>
            <Card
              style={{ maxWidth: "600px", margin: "0 auto", textAlign: "left" }}
              className="shadow mt-5"
            >
              <Card.Header>
                <span className="text-muted">Search Name:</span>
                <span className="ml-3" style={{ fontSize: "1.4em" }}>
                  {searchName}
                </span>
              </Card.Header>
              <Card.Body>
                <Row noGutters>
                  <Col xs={12} sm={6}>
                    <SelectedParams
                      selectedParams={geographyParams}
                      dict={dict["geography"]}
                      dictKey="cols"
                      paramHeader={`Geography`}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <SelectedParams
                      selectedParams={searchParams}
                      dict={dict["cols"]}
                      dictKey="cols"
                      paramHeader={`${dict.type} filters`}
                    />
                  </Col>
                </Row>
              </Card.Body>
              {showLeadsCount && (
                <ListGroup
                  className="list-group-flush"
                  style={{ marginBottom: "-20px" }}
                >
                  <ListGroupItem>
                    <LeadsCount
                      inline={true}
                      count={search.count || search.downloadCount}
                      readOnly={true}
                    />
                  </ListGroupItem>
                </ListGroup>
              )}
            </Card>
            {user && (
              <div
                style={{ maxWidth: "600px", margin: "0 auto" }}
                className="d-flex justify-content-between align-items-center mt-5"
              >
                <CopyToClipboard
                  text={`${window.location.href}`}
                  onCopy={() => {
                    console.log("Copied search share link to your clipboard.");
                    handleAlerts(
                      "",
                      "Copied the link to your clipboard",
                      "success"
                    );
                  }}
                >
                  <Button className="ml-2" variant="light">
                    <i className="fa fa-clone" aria-hidden="true"></i>&nbsp;Copy
                    Link
                  </Button>
                </CopyToClipboard>
                <Link
                  to={`/_search/${dict.dictId}${window.location.search}`}
                  className={`btn btn-${dict.themeColor}`}
                >
                  Use this Search
                </Link>
              </div>
            )}
            {!user && (
              <div
                style={{ maxWidth: "600px", margin: "0 auto" }}
                className="d-flex justify-content-between align-items-center mt-5 mb-5"
              >
                <span>Want to use this search?</span>
                <div>
                  <Link
                    className="btn btn-link mr-4"
                    to={`/login?next=${
                      window.location.pathname
                    }${encodeURIComponent(window.location.search)}`}
                  >
                    Login
                  </Link>
                  <Link
                    className="btn btn-primary btn-lg"
                    to={`/signup?next=${
                      window.location.pathname
                    }${encodeURIComponent(window.location.search)}`}
                  >
                    Signup Now
                  </Link>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    );
  }

  return null;
};

ShareSearch.propTypes = {
  user: PropTypes.object,
  handleAlerts: PropTypes.func,
  handleDistract: PropTypes.func,
  handleIsApp: PropTypes.func
};

const ShareDownload = props => {
  return null;
};

const ShareButton = props => {
  const { type, cb } = props;

  if (type === "download") {
    return (
      <CopyToClipboard text={shareLink({ ...props })} onCopy={cb}>
        {props.children}
      </CopyToClipboard>
    );
  }

  return (
    <CopyToClipboard text={shareLink({ ...props })} onCopy={cb}>
      {props.children}
    </CopyToClipboard>
  );
};

const shareLink = props => {
  const { type, downloadId, aid, searchId, uid } = props;

  if (type === "download") {
    return `${window.location.origin}/share/search?downloadSearch=${downloadId}&aid=${aid}`;
  }

  return `${window.location.origin}/share/search?savedSearch=${searchId}&uid=${uid}`;
};

export { ShareSearch, ShareDownload, ShareButton, shareLink };
