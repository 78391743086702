
import React, {useState, useEffect} from "react";
import {
  Table,
  Image,
  ButtonGroup,
  Button,
  Badge,
  Form,
  InputGroup,
  Modal,
  ListGroup
} from "react-bootstrap";
import {
  Link,
  Redirect
} from "react-router-dom";
import withBulkSelect from "./withBulkSelect.jsx";
import {createArticle} from "./interfaceDigests.jsx";
import {Collection, CollectionContext} from "./firebaseView.jsx";
import {db, batchWrite, firebase} from "./firebase.jsx";
import {
  Trash,
  ArrowBarDown,
  Tags,
  PersonPlus
} from "react-bootstrap-icons";
import JSONPretty from "react-json-pretty";
import { ShareButton } from "./shareDigest.jsx";
import ManageContentTags from "./manageContentTags.jsx";

const ContentFromLink = props => {

  const {size, fetchFromApi, handleAlerts, userDoc, user, onSuccess} = props;
  const [link, setLink] = useState("");
  const [fetchingLink, setFetchingLink] = useState(false);
  const [linkPreview, setLinkPreview] = useState(null);

  const [aTitle, setaTitle] = useState("");
  const [preview, setPreview] = useState("");
  const [previewLink, setPreviewLink] = useState("");
  const [linkText, setLinkText] = useState("Read More");
  const [img, setImg] = useState("");

  useEffect( () => {
    if (!link) {
      return console.log("No link to fetch.");
    }
    let timeOutId = setTimeout(() => {
      console.log("Done typing.");
      let body = {
        url: link
      };
      fetchFromApi({
        body,
        endpoint: "/api/scrape/ogtags"
      }, (error, preview) => {
        if (error) {
          handleAlerts("", "Error fetching link preview. Try another link.", "warning");
          setFetchingLink(false);
        }
        if (preview) {
          let { title, altTitle, description, altDescription, altImg} = preview;
          setaTitle(
            preview["og:title"] ? preview["og:title"] : title ? title : altTitle ? altTitle : ""
          );
          setImg(
            preview["og:image"] ? preview["og:image"] : altImg ? altImg : ""
          );
          setPreview(
            preview["og:description"] ? preview["og:description"] : description ? description : altDescription ? altDescription : ""
          )
          setPreviewLink(
            link ? link : ""
          );
          setLinkPreview(preview);
          setFetchingLink(false);
        }
      });
    }, 1000);

    return () => {
      console.log("Clearing timeout");
      clearTimeout(timeOutId);
    };
  }, [link]);

  return(
    <Form 
      onSubmit={e => {
        e.preventDefault();
        const { hostname } = new URL(previewLink);
        createArticle({
          aid: userDoc.id,
          uid: user.uid,
          title: aTitle,
          preview,
          imgUrl: [img],
          link: previewLink,
          linkText,
          category: ["link", hostname]
        }, (error, newArticle) => {
          if (error) {
            handleAlerts("", "Error creating content from link. Please try again.", "warning");
          }
          if (newArticle) {
            handleAlerts("", "Wahoo! You added content from a link!", "success");
            if (onSuccess) {
              onSuccess(newArticle);
            }
          }
        });
      }}
    >
      <Form.Group>
        <Form.Control 
          type="text"
          value={link}
          onChange={ e => {
            setLink(e.target.value);
            setFetchingLink(true);
          }}
          size={size ? size : "lg"}
          placeholder="Paste your link here"
        />
        
        {fetchingLink &&
          <ListGroup>
            <ListGroup.Item variant="light">
              One sec, we're getting your link
            </ListGroup.Item>
            </ListGroup>
        }
      </Form.Group>
      {linkPreview &&
        <React.Fragment>
          <JSONPretty
            className="text-left"
            id="json-pretty"
            data={linkPreview}
          />
          {img && 
            <Image src={img} rounded style={{width: "100%", height: "auto"}}/>
          }
          <Form.Group>
            <Form.Label>Title</Form.Label>
            <Form.Control 
              value={aTitle}
              onChange={e => {
                setaTitle(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Preview</Form.Label>
            <Form.Control
              as="textarea"
              rows="2"
              value={preview}
              onChange={e => {
                setPreview(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Button</Form.Label>
            <Form.Control 
              value={linkText}
              onChange={e => {
                setLinkText(e.target.value);
              }}
            />
          </Form.Group>
          <Button type="submit"
            className="pull-right"
          >
            Add to Content
          </Button>
        </React.Fragment>        
      }
    </Form>
  )
}

const NewLinkBtn = props => {
  const {variant, btnTitle} = props;

  const [showModal, setShowModal] = useState(false);

  return(
    <React.Fragment>
      <Button
        variant={variant ? variant : "primary"}
        onClick={() => setShowModal(true)}
      >
        {btnTitle ? btnTitle : "New Link"}
      </Button>
      {showModal &&
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Body>
            <ContentFromLink {...props} 
              onSuccess={() => setShowModal(false)}
            />
          </Modal.Body>
          <Modal.Footer className="justify-content-start">
            <Button size="sm"
              onClick={() => setShowModal(false)}
              variant="light"
            >
              Hide
            </Button>
          </Modal.Footer>
        </Modal>
      }
    </React.Fragment>
  )
}

const ContentHeader = props => {
  let {userDoc, selectedData, handleAlerts, docs, handleBulkSelectState, user} = props;
  let [redirect, setRedirect] = useState(null);
  if (redirect) {
    return <Redirect to={`/article/${redirect}`} />
  }

  let hasSelectedArticles = selectedData.length > 0 ? true : false;
  return(
    <div className="d-flex flex-row justify-content-between mt-5 mb-5">
      <div>&nbsp;</div>
      <ButtonGroup>
        <NewLinkBtn {...props} variant="outline-primary" />
        <Button
          variant="outline-primary"
          onClick={async e => {
            e.preventDefault();
            let newArticle = await createArticle({aid: userDoc.id, uid: user.uid});
            console.log("newArticle: ", newArticle);
            setRedirect(newArticle._docId);
          }}
        >
          New Article
        </Button>
        <Link
          to="/import"
          className="btn btn-outline-primary"
        >
          <ArrowBarDown size={30} />
        </Link>
        {hasSelectedArticles &&
          <React.Fragment>
            <ManageContentTags {...props} variant="outline-success">
              <Tags size={30} />
            </ManageContentTags>
            <ShareButton {...props} />
            <Button
              onClick={async () => {
                console.log("Clicked to delete articles");
                if ( window.confirm(`Are you sure you want to delete ${selectedData.length} articles? This CANNOT be undone.`)) {
                  let data = docs.map( d => {
                    if (selectedData.includes(d._docId)) {
                      let nd = {};
                      nd.op = "delete";
                      nd.ref = d._docRef;
                      return nd
                    }
                    return undefined
                  }).filter( d => typeof d !== "undefined");
                  let deletedStats = await batchWrite({data});
                  console.log("deletedStats: ", deletedStats);
                  handleAlerts("",`Successfully deleted ${deletedStats.length} articles.`, "success");
                  handleBulkSelectState({selectedData: []});
                }
              }}
              variant="outline-danger"
            >
              <Trash size={30} />
            </Button>
          </React.Fragment>
        }
      </ButtonGroup>
    </div>
  )
}

const ContentTable = props => {
  const {docs, BulkHeader, SelectItem} = props;
  //console.log("ContentTable props: ", props);
  const placeholder = "https://via.placeholder.com/100/FFFFFF/FFFFFF";
  return(
    <Table responsive>
      <thead>
        <tr>
          <th>
            <BulkHeader
              data={docs}
              idField="_docId"
            />
          </th>
          <th>Article</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        {docs.map( d => {
          let {_docId, _created, category} = d;
          let {imgUrl, preview, title} = d.data;

          return(
            <tr key={`tbtr_${_docId}`}>
              <td>
                <SelectItem id={_docId} />
              </td>
              <td>
                <Link to={`/article/${_docId}`}>
                  <div className="d-flex flex-row flex-start">
                    <div style={{ width: "100px", height: "100px"}}>
                      {imgUrl.length > 0 &&
                        <Image src={imgUrl[0]} style={{ width: "100px", height: "100px", objectFit: "cover"}}  rounded />
                      }
                      {imgUrl.length === 0 &&
                        <Image src={placeholder} style={{ width: "100px", height: "100px", objectFit: "cover"}}  rounded />
                      }
                    </div>
                    <div className="ml-3 d-flex flex-column justify-content-between">
                      <div>
                        <b className="text-dark">{title}</b><br />
                        <small className="text-muted">{preview}</small><br />
                      </div>
                      <div className="d-flex flex-row">
                        {category.map( (c,i) => {
                          return(
                            <Badge key={`cat_${i}`} pill variant="light">{c}</Badge>
                          )
                        })}
                      </div>

                    </div>
                  </div>
                </Link>
              </td>
              <td style={{minWidth: "120px"}}>
                <small className="text-muted">
                  {new Date( _created * 1000).toLocaleString('en-US', {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
              </small>

              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

const Content = props => {
  //console.log("Content props: ", props);
  let { userDoc, handleState } = props;
  useEffect( () => {
    handleState({isApp: true});
  }, [handleState]);
  return (
    <React.Fragment>
      <Collection
        colRef={db}
        collection="content"
        permissions={["read", "write", "delete"]}
        where={[
          { field: "aid", operand: "==", term: userDoc.id },
          { field: "archived", operand: "!=", term: true }
        ]}
        orderBy={[{ field: "archived" }, { field: "_created", order: "desc" }]}
      >
        <CollectionContext.Consumer>
          {(context) => {
            return(
              <React.Fragment>
                <ContentHeader {...props} {...context} />
                <ContentTable {...props} {...context} />
              </React.Fragment>
            )
          }}
        </CollectionContext.Consumer>
      </Collection>
    </React.Fragment>
  )
}

export default withBulkSelect(Content);
