import React from "react";
import PropTypes from "prop-types";
import { Redirect, Link } from "react-router-dom";
import { firebaseApp, db } from "./firebase.jsx";
import { Form, Button, InputGroup } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { SubscribeRecurly as Subscribe } from "./recurlyCheckoutAgain.jsx";
import {Eye, EyeSlash} from "react-bootstrap-icons";
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pass: "",
      confirmpass: "",
      email: "",
      isLoggingIn: false,
      isCreate: true,
      showPassword: false
    };
    this.loginWithEmail = this.loginWithEmail.bind(this);
  }

  async loginWithEmail(e) {
    e.preventDefault();
    let { email, pass, isCreate } = this.state;
    this.setState({ isLoggingIn: true });
    try {
      let providers = await firebaseApp
        .auth()
        .fetchSignInMethodsForEmail(email);
      let isInvited;
      // Check if this is an invited user
      await db
        .collection("users")
        .where("email", "==", email)
        .get()
        .then(snapShot => {
          snapShot.forEach(doc => {
            let invitedDoc = doc.data();
            invitedDoc._docId = doc.id;
            invitedDoc._docPath = doc.ref.path;
            isInvited = invitedDoc;
          });
        })
        .catch(err =>
          console.log("Something went wrong checkng for existing users: ", err)
        );
      if (
        providers.length === 0 &&
        (isCreate || typeof isInvited !== "undefined")
      ) {
        // createUser
        console.log("Creating a new user");
        await firebaseApp.auth().createUserWithEmailAndPassword(email, pass);
        return false;
      } else {
        // sign user in
        //console.log("Signing the user in")
        await firebaseApp.auth().signInWithEmailAndPassword(email, pass);
        // if successful set loaded false the account can load and set it true
        this.props.handleState({ loaded: false });
        return true;
      }
    } catch (error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.error("There was a problem logging in!", errorCode, errorMessage);
      this.props.handleAlerts(
        "",
        `Uh oh, there was a problem logging you in. ${error.message}`,
        "warning"
      );
      this.setState({ isLoggingIn: false });
      this.props.handleState({ loaded: true });
    }
  }

  render() {
    //console.log("login this.props: ", this.props, "lgin this.state: ", this.state);
    let {showPassword} = this.state;
    let { customerDoc, manualSubscribe } = this.props;
    let alreadyHasSub = !customerDoc
      ? false
      : customerDoc.subscription_status === "active"
      ? true
      : false;
    let isCreate = window.location.pathname !== "/login" && !alreadyHasSub;
    let topPadding = isCreate ? "5%" : "15%";
    let isUser = this.props.user !== null;
    const urlParams = new URLSearchParams(window.location.search);
    const next = urlParams.get("next") || process.env.REACT_APP_redirect_path;
    //console.log("next: ", next);

    let noMatch =
      this.state.confirmpass.length > 0 &&
      this.state.pass !== this.state.confirmpass;
    let passMatch = !isCreate
      ? true
      : this.state.confirmpass.length > 0 &&
        this.state.pass === this.state.confirmpass;

    if (!isUser || isCreate) {
      return (
        <div
          style={{
            maxWidth: "410px",
            margin: "0 auto",
            padding: "10px",
            paddingTop: topPadding
          }}
        >
          <h3>{!isCreate ? "Login" : "Signup"}</h3>
          {!isUser && (
            <React.Fragment>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address*</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  onChange={e => this.setState({ email: e.target.value })}
                  required
                  value={this.state.email}
                  readOnly={this.props.user !== null}
                />
                {this.state.email !== "" && this.state.email.includes("@") && (
                  <Form.Text className="text-success">
                    Email looks good :)
                  </Form.Text>
                )}
                {this.state.email !== "" && !this.state.email.includes("@") && (
                  <Form.Text className="text-danger">
                    Uh oh, that doesn't look like an email address.
                  </Form.Text>
                )}
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password*</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={ showPassword ? "text" : "password"}
                    placeholder="Password"
                    onChange={e => this.setState({ pass: e.target.value })}
                    readOnly={this.props.user !== null}
                  />
                  <InputGroup.Append>
                    <Button
                      variant={showPassword ? "info" : "outline-info"}
                      onClick={() => {
                        this.setState({showPassword: !showPassword});
                      }}
                    >
                      {!showPassword &&
                        <Eye />
                      }
                      {showPassword &&
                        <EyeSlash />
                      }
                    </Button>
                  </InputGroup.Append>
                </InputGroup>


              </Form.Group>
              {!alreadyHasSub && isCreate && (
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Confirm Password*</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={ showPassword ? "text" : "password" }
                      placeholder="Confirm Password"
                      onChange={e =>
                        this.setState({ confirmpass: e.target.value })
                      }
                      readOnly={this.props.user !== null}
                    />
                    <InputGroup.Append>
                      <Button
                        variant={showPassword ? "info" : "outline-info"}
                        onClick={() => {
                          this.setState({showPassword: !showPassword});
                        }}
                      >
                        {!showPassword &&
                          <Eye />
                        }
                        {showPassword &&
                          <EyeSlash />
                        }
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                  {noMatch && (
                    <Form.Text className="text-danger">
                      Uh oh, your passwords don't match
                    </Form.Text>
                  )}
                  {passMatch && (
                    <Form.Text className="text-success">
                      Perfect, those passwords match.
                    </Form.Text>
                  )}
                </Form.Group>
              )}
            </React.Fragment>
          )}
          {isCreate && isUser && manualSubscribe && (
            this.props.manualSubscribeComponent
          )}
          {isCreate && isUser && !manualSubscribe && (
            <Subscribe
              {...this.props}
              isSubscribe={true}
              byPassVerification={
                this.props.userDoc.verified
                  ? this.props.userDoc.verified
                  : false
              }
            />
          )}

          {!isCreate && (
            <Button
              variant="primary"
              type="button"
              onClick={this.loginWithEmail}
              disabled={this.state.isLoggingIn}
              id="sign-in-button"
            >
              Login &nbsp;
              {this.state.isLoggingIn && (
                <Spinner
                  animation="grow"
                  variant="light"
                  size="sm"
                  as="span"
                  role="status"
                />
              )}
            </Button>
          )}
          {isCreate && !isUser && (
            <React.Fragment>
              <Form.Text className="mt-4 mb-4">
                By creating an account you agree to our{" "}
                <Link to="/terms">terms of service</Link> and{" "}
                <Link to="/privacy">privacy policy</Link>.
              </Form.Text>
              <Button
                variant="primary"
                type="button"
                onClick={this.loginWithEmail}
                disabled={
                  this.state.isLoggingIn ? true : isCreate && !passMatch
                }
                id="sign-in-button"
              >
                Create account &nbsp;
                {this.state.isLoggingIn && (
                  <Spinner
                    animation="grow"
                    variant="light"
                    size="sm"
                    as="span"
                    role="status"
                  />
                )}
              </Button>
            </React.Fragment>
          )}

          <Form.Text className="text-muted">
            {!isCreate && (
              <React.Fragment>
                <span>
                  Don't have an account?{" "}
                  <Link to="/signup" className="btn btn-sm btn-link">
                    Signup here.
                  </Link>
                </span>
                <br />
                <span>
                  Forgot your password?{" "}
                  <Link to="reset_password" className="btn btn-sm btn-link">
                    Request a new one here.
                  </Link>
                </span>
              </React.Fragment>
            )}
            {isCreate && (
              <span>
                Already have an account?{" "}
                <Link to="/login" className="btn btn-sm btn-link">
                  Login here.
                </Link>
              </span>
            )}
          </Form.Text>
        </div>
      );
    } else {
      return <Redirect to={next} />;
    }
  }

  componentDidMount() {
    if (!this.props.isCreate) {
      console.log("cDM this.props.isSignup: ", this.props.isCreate);
      this.setState({ isCreate: false });
    }
    if (this.props.handleState) {
      //this.props.noDistract(true);
      this.props.handleState({
        noDistract: true,
        alertShow: false,
        isApp: false
      });
    }
  }

  componentWillUnmount() {
    if (this.props.handleState) {
      this.props.handleState({ noDistract: false });
    }
    if (this.props.user) {
      var unsubscribe = db
        .collection("users")
        .onSnapshot(function(querySnaphot) {
          // do something with the data.
          console.log("Unsubscribing from changes to the users collection");
        });
      // Stop listening to changes
      unsubscribe();
    }
  }
}

Login.propTypes = {
  user: PropTypes.object,
  noDistract: PropTypes.bool,
  isSignUp: PropTypes.bool,
  plan: PropTypes.object,
  hasPaid: PropTypes.bool,
  signIn: PropTypes.func,
  handleAlerts: PropTypes.func
};

export default Login;
