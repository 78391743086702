export default {
    appLogo: "https://d15tnd3q55f8nl.cloudfront.net/static/SG_Twilio_Lockup_Social-56f3cfd2f6b0c62422980170d57fac64.png",
    appName: "sendgrid",
    data: {
      apiKey: "",
      sendFromEmail: [""]
    },
    lastDelivery: 0,
    type: "send",
    // Added later on:
    // lastUpdated: Number secSinceEpoc,
    // _created: Number secSinceEpoc,
    // archived: Boolean 
  }
  