import React, {useState, useEffect} from "react";
import { Button, Modal, Form, InputGroup } from "react-bootstrap";
import {db, batchWrite, firebase} from "./firebase.jsx";

const ManageContentTags = props => {
  const {docs, selectedData, handleBulkSelectState, handleAlerts, userDoc, onCategoryChange } = props;
  //console.log("ManageContentTags props: ", props);
  const [showModal, setShowModal] = useState(false);
  const [currentTags, setCurrentTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [newTag, setNewTag] = useState("");
  const [configDoc, setconfigDoc] = useState(null);
  const configRef = db.collection("digests").doc(userDoc.id);
  useEffect( () => {
    if (!configDoc) {
      configRef.onSnapshot( doc => {
        if (doc.exists) {
          let d = doc.data();
          d._docRef = doc.ref;
          d._docId = doc.id;
          setconfigDoc(d);
          setCurrentTags(d.categories ? d.categories : currentTags);
        } else {
          configRef.set({
            categories: []
          })
        }
      })
    }
    let cts = [];
    let data = []
    docs.forEach( d => {
      cts = [...new Set([...currentTags, ...cts, ...d.category])]
      if ( selectedData.includes(d._docId)) {
        data.push(d.category);
      }
    })
    // Calculate the tags that are common to all the docs so we can show those to the user
    let result = data.length > 0 ? data.reduce((a,b) => a.filter(c => b.includes(c))) : [];
    //console.log("data: ", data, "result: ", result);
    setSelectedTags(result);
    setCurrentTags(cts);
  }, [docs]);
  return(
    <React.Fragment>
      <Button
        variant={props.variant ? props.variant : "primary"}
        className={props.className ? props.className : ""}
        style={props.style ? props.style : null}
        onClick={() => setShowModal(true)}
      >
        {props.children ? props.children : "Manage Tags"}
      </Button>
      {showModal &&
        <Modal show={showModal} onHide={() => setShowModal(false)}
          size={props.modalSize ? props.modalSize : "md"}
        >
          <Modal.Header>
            <Modal.Title>Manage Tags</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Select the tag(s) you'd like applied to the selected article(s)</Form.Label>
              {currentTags.map( t => {
                return(
                  <Form.Check
                    key={t}
                    id={`ct_${t}`}
                    type="checkbox"
                    label={t}
                    checked={selectedTags.includes(t)}
                    onChange={() => {
                      if (selectedTags.includes(t)) {
                        setSelectedTags(selectedTags.filter(ct => ct !== t))
                      } else {
                        setSelectedTags([...selectedTags, t]);
                      }
                    }}
                  />
                )
              })}
            </Form.Group>
            <InputGroup>
              <Form.Control
                size="sm"
                type="text"
                value={newTag}
                onChange={e => setNewTag(e.target.value)}
                placeholder="Type new tag name"
              />
              <InputGroup.Append>
                <Button variant="outline-secondary"
                  size="sm"
                  onClick={() => {
                    db.collection("digests").doc(userDoc.id).update({
                      categories: firebase.firestore.FieldValue.arrayUnion(newTag)
                    })
                    setCurrentTags([...currentTags, newTag]);
                    setNewTag("");
                  }}
                >
                  Create new
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Modal.Body>
          <Modal.Footer className="d-flex flex-row justify-content-between">
            <Button onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button
              onClick={ async () => {
                let data = docs.map( d => {
                  if (selectedData.includes(d._docId)) {
                    let nd = {};
                    nd.op = "update";
                    nd.ref = d._docRef;
                    nd.data = {category: selectedTags}
                    return nd
                  }
                  return undefined
                }).filter( d => typeof d !== "undefined");
                let stats = await batchWrite({data});
                console.log("stats: ", stats);
                handleAlerts("",`Successfully updated tags for ${stats.length} articles.`, "success");
                if (handleBulkSelectState) {
                  handleBulkSelectState({selectedData: []});
                }
                if (onCategoryChange) {
                  onCategoryChange(selectedTags);
                }
                setShowModal(false);
              }}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      }
    </React.Fragment>
  )
}

export default ManageContentTags;