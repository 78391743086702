import React from "react";
import { Route } from "react-router-dom";
import { Helmet } from "react-helmet";

import Login from "./login.jsx";
//import Paywall from "./paywall.jsx";
import CustomPlan from "./custom_plan.jsx";
import ResetPass from "./resetPassword.jsx";
import ChangePass from "./changePassword.jsx";
import SettingsRouter from "./settingsRouter.jsx";

const SharedRoutes = props => {
  let { selectPlan, selectedPlan, handleDistract, plans } = props;

  return(
    <React.Fragment>
      <Route path="/_subscribe/">
        <Helmet>
          <title>Subscribe</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <CustomPlan
          selectPlan={selectPlan}
          selectedPlan={selectedPlan}
          plans={plans}
          handleDistract={handleDistract}
        />
      </Route>
      <Route path="/login">
        <Helmet>
          <title>Login</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <Login {...props} isSignUp={true} plan={plans[selectedPlan]} />
      </Route>
      <Route path="/signup">
        <Helmet>
          <title>Sign up</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <Login
          manualSubscribe={false}
          {...props}
          isCreate={true}
          plan={plans[selectedPlan]}
        />
      </Route>
      <Route path="/reset_password">
        <Helmet>
          <title>Reset password | {process.env.REACT_APP_site_name}</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <ResetPass {...props} noDistract={true} />
      </Route>

      <Route path="/change_password">
        <Helmet>
          <title>Change password</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <ChangePass {...props} />
      </Route>
      <Route path="/settings">
        <SettingsRouter {...props} />
      </Route>
    </React.Fragment>
  )
}

export default SharedRoutes;
