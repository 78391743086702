import React, {useState} from "react";
import {db, firebaseApp} from "./firebase.jsx";
import { Form, Button } from "react-bootstrap";

const UpdateEmail = props => {
  console.log("UpdateEmail props: ", props);
  const { customerDoc, customerDocId, user, userDoc, apiKey } = props;
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currPass, setCurrPass] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const reauthenticate = async e => {
    e.preventDefault();
    let fbUser = firebaseApp.auth();

    try {
      await fbUser.signInWithEmailAndPassword(user.email, currPass);
      console.log(`Successfully reauthenticated`);
      props.handleAlerts(e, `You're in! Nice work. :)`, "success");
      setIsAuthenticated(true);
      setNewEmail("");
    } catch (error) {
      console.log("Uh oh, that didn't work :(", error);
      props.handleAlerts(e, "Uh oh, that didn't work :(", "warning");
    }
  };
  const changeEmail = async e => {
    e.preventDefault();
    let fbUser = firebaseApp.auth().currentUser;
    let nEmail = newEmail.toLowerCase();
    console.log("nEmail: ", nEmail);
    try {
      if (user.email === customerDoc.email) {
        // Change the email on the account users doc
        db.collection("users")
          .doc(userDoc.uid)
          .update({ email: nEmail });
        // Change the email on the customers doc
        db.collection("customers")
          .doc(customerDocId)
          .update({
            email: nEmail
          });
      } else {
        // Change the email in the userDoc
        db.collection("users")
          .doc(user.uid)
          .update({
            email: nEmail
          });

        // Change the email in the company users of the account users doc
        let companyUsers = userDoc.companyUsers;
        let uUsers = [];
        companyUsers.map(coUser => {
          console.log(coUser.email, user.email);
          if (coUser.email === user.email) {
            return uUsers.push({
              name: coUser.name,
              email: nEmail,
              role: coUser.role
            });
          } else {
            return uUsers.push(coUser);
          }
        });
        console.log("uUsers: ", uUsers);
        db.collection("users")
          .doc(userDoc.uid)
          .update({
            companyUsers: uUsers
          });
      }

      // Change the email in FirebaseAuth
      await fbUser.updateEmail(nEmail);

      // Change the email in the billing system
      const init = {
        method: "POST",
        headers: {
          authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json"
        },
        "Transfer-Encoding": "chunked",
        cache: "default",
        accept: "application/json",
        body: JSON.stringify({
          email: nEmail
        })
      };
      let response = await fetch(
        `${process.env.REACT_APP_api_url}/api/recurly/accounts/update/${customerDoc.customer_id}`,
        init
      );
      let newAccount = await response.json(); // If returned as JSON string
      window.location.reload();

      console.log(
        `Email successfully updated to ${nEmail}. Recurly account updated: `,
        newAccount
      );
      props.handleAlerts(
        e,
        `Email successfully updated to ${nEmail}`,
        "success"
      );
      setNewEmail("");
    } catch (error) {
      console.log("Email was not updated: ", error);
      props.handleAlerts(
        e,
        `Email was not updated: ${error.message}`,
        "warning"
      );
    }
  };
  return (
    <Form
      style={{
        maxWidth: "410px",
        margin: "0 auto",
        padding: "10px",
        paddingTop: "15%"
      }}
    >
      <h3>Update email</h3>
      <Form.Group>
        <Form.Label>Current account email</Form.Label>

        <Form.Control type="email" value={props.user.email} disabled={true} />
        {!isAuthenticated && (
          <React.Fragment>
            <Form.Control
              type="password"
              name="currPass"
              placeholder="Enter your current password"
              value={currPass}
              onChange={e => setCurrPass(e.target.value)}
            />
            <br />
            <Button
              variant="secondary"
              disabled={!(currPass !== "")}
              onClick={e => reauthenticate(e)}
            >
              Submit
            </Button>
          </React.Fragment>
        )}
      </Form.Group>
      {isAuthenticated && (
        <Form.Group>
          <Form.Label>New account email</Form.Label>
          <Form.Control
            name="newEmail"
            type="text"
            placeholder="Enter your new email address"
            value={newEmail}
            onChange={e => setNewEmail(e.target.value)}
          />
          {newEmail !== "" && newEmail.includes("@") && (
            <p className="text-success">Email looks good.</p>
          )}
          {newEmail !== "" && !newEmail.includes("@") && (
            <p className="text-danger">
              Uh oh, that doesn't look like an email address.
            </p>
          )}
          <br />
          <Button
            variant="secondary"
            disabled={!(newEmail !== "" && newEmail.includes("@"))}
            onClick={e => changeEmail(e)}
          >
            Update email
          </Button>
        </Form.Group>
      )}
    </Form>
  );
};

export default UpdateEmail;
