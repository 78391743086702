import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import withApp from "./withApp.jsx";
import ScrollToTop from "./scrollToTop.jsx";
import { Container, Row, Col, Form } from "react-bootstrap";
import SharedRoutes from "./sharedRoutes.jsx";
import DigestRoutes from "./digestRoutes.jsx";
import { Topnav, Footer, FooterSmall, SideBar, settingsPanes } from "./interfaceDigests.jsx";
import AppAlerts from "./appAlerts.jsx";
import {UserProfile} from "./userProfile.jsx";

const AppDigest = props => {
  const {noDistract, isApp} = props;
  //console.log("AppDigest props: ", props);
  return(
    <Router>
      <ScrollToTop>
        {!noDistract &&
          <Topnav {...props} />
        }
        <Container fluid>
          <Row noGutters={true} style={{minHeight: "100vh"}}>
            {isApp && (
              <SideBar {...props} />
            )}
            <Col>
              <AppAlerts {...props} />
              <DigestRoutes {...props} />
              <SharedRoutes
                panes={settingsPanes}
                {...props}
                manualSubscribe={true}
                manualSubscribeComponent={
                  <UserProfile
                    {...props}
                    heading={
                      <React.Fragment>
                        <h6 className="mt-3">Create your account profile </h6>
                        <Form.Text className="mb-3">
                          Please create and verify your account information.
                        </Form.Text>
                      </React.Fragment>
                    }
                    isCreate={true}
                    size="md"
                    nameText="This will be displayed to your organization."
                    phoneText="We'll use this to make sure you are a real person and verify your account."
                    emailText="We'll use this to contact you via email and verify your account."
                    addInfo={[]}
                  />
                }
              />
            </Col>
          </Row>
        </Container>
        {!noDistract && !isApp && <Footer {...props} />}
        {!noDistract && isApp && <FooterSmall />}
      </ScrollToTop>
    </Router>
  )
}

export default withApp(AppDigest);
