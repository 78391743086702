import React, {useState, useEffect} from "react";
import {
  Table,
  Button,
  Image,
  Form,
  ButtonGroup
} from "react-bootstrap";
import {
  ArrowUpRightSquare,
  ChevronLeft,
  ChevronRight
} from "react-bootstrap-icons";
import {getFirstSentence, createArticle} from "./interfaceDigests.jsx";
import withBulkSelect from "./withBulkSelect.jsx";
import {db, firebase} from "./firebase.jsx";

const ImportHeadway = props => {
    console.log("ImportHeadway props: ", props);
    const {fetchFromApi, apiToken, changelogUrl, apiKey, handleBulkSelectState, BulkHeader, SelectItem, selectedData, userDoc, user, integration, handleAlerts} = props;
    const [posts, setPosts] = useState({articles:[]});
		const [fetchedPosts, setFetchedPosts] = useState(false);
  
    // Fetch parameters
    const [sort, setSort] = useState("posted");
    const [postType, setPostType] = useState("all");
    const [search, setSearch] = useState("");
    const [limit, setLimit] = useState("");
    const [offset, setOffset] = useState("");
  
    useEffect( () => {
      fetchFromApi({
        endpoint: "/api/scrape/headway",
        body: {url: changelogUrl}
      }, (error,posts) => {
        if (error) {
          console.log("Error getting headway stuff: ", error);
					setFetchedPosts(true);
        }
        if (posts) {
          if (!posts.articles) {
            posts.articles = [];
          }
          setPosts(posts);
					setFetchedPosts(true);
        }
      })
    }, [ changelogUrl ]);
  
    let hasPosts = posts.articles ? posts.articles.length > 0 ? true : false : false;
    let hasPagination = false;

    return(
      <React.Fragment>
        {selectedData.length > 0 &&
          <div className="d-flex flex-row justify-content-end">
            <Button
              onClick={() => {
                let bulkData = posts.articles.map( d => {
                  let nd={};
                  if (selectedData.includes(d.id) ) {
                    nd._created = Date.parse(d.datePublished)/1000;
                    nd.title = d.title ? d.title : "No Title :(";
                    nd.content = d.content ? d.content : "No content :(";
                    nd.contentType = "text";
                    nd.preview = getFirstSentence(d.content ? d.content : "No preview :(");
                    nd.link = `${new URL(changelogUrl).origin}${d.link}`;
                    nd.imgUrl = d.contentImages
                      ? d.contentImages
                      : [];
                    return nd;
                  }
                  return undefined;
                }).filter( nd => typeof nd !== "undefined");
                console.log("bulkdData: ", bulkData);
                createArticle({
                  bulkData,
                  aid: userDoc.id,
                  uid: user.uid,
                  category: ["headway"]
                }, (error, summary) => {
                  if (error) {
                    console.log("Error importing headway content: ", error);
                  }
                  if (summary) {
                    console.log("Successfully imported headway content: ", summary);
                    // update the integration with the ids of the articles that were imported
                    let allIds = [...integration.importedContent, ...selectedData];
                    let uniqueIds = [...new Set(allIds)];
                    integration._docRef.update({importedContent: uniqueIds});
                    db.collection("digests").doc(userDoc.id).update({
                      categories: firebase.firestore.FieldValue.arrayUnion("forumbee")
                    });
                    handleAlerts("", `Yay, you imported ${selectedData.length} new items!`, "success");
                    console.log("Successfully updated integration document: ", uniqueIds);
                    handleBulkSelectState({selectedData: []});
                  }
                })
              }}
            >
              Import {selectedData.length} Items
            </Button>
          </div>
        }
        <Form.Label>Select Content Type</Form.Label>
        <div className="d-flex flex-row justify-content-start flex-wrap mb-2">
          {["all"].map( type => {
            return(
              <Button
                key={`ptbtn_${type}`}
                className="m-1"
                size="sm"
                variant={postType === type ? "secondary" : "outline-secondary"}
                onClick={() => setPostType(type)}
              >
                {type}
              </Button>
            )
          })}
        </div>
        <Table responsive>
          <thead>
            <tr>
              <th>
                <BulkHeader
                  data={posts.articles}
                  idField="id"
                  totalItems={posts.articles.length}
                />
              </th>
              <th>Image</th>
              <th>Post</th>
              <th>Created</th>
            </tr>
          </thead>
          <tbody>
            {hasPosts && posts.articles.map( (post,i) => {
              //console.log("post: ", post);
              return(
                <tr key={`p_${i}`}>
                  <td>
                    <SelectItem
                      id={post.id}
                      className={integration.importedContent.includes(post.id) ? "text-warning" : "text-primary"}
                    />
                  </td>
                  <td>
                    {post.contentImages &&
                      <div style={{width: "50px", height: "50px"}}>
                        <Image thumbnail
                          src={ post.contentImages[0] }
                          style={{
                            width: "50px",
                            height: "50px",
                            objectFit: "cover"
                          }}
                        />
                      </div>
                    }
                    {/*post.hasMedia && post.media.data[0].mediaType === 2 &&
                      <div dangerouslySetInnerHTML={{__html: post.media.data[0].mediaUrl}} />
                    */}
                    {!post.contentImages && <span>&nbsp;</span>}
                  </td>
                  <td>
                    {post.title}&nbsp;<a target="_blank" rel="noopener noreferrer" href={`${changelogUrl}/${post.link}`}><ArrowUpRightSquare /></a><br />
                    <small className="text-italic">{getFirstSentence(post.content)}</small>
                  </td>
                  <td style={{minWidth: "120px", fontSize: ".8rem"}}>{new Date(Date.parse(post.datePublished)).toLocaleString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric"
                    })}</td>
                </tr>
              )
            })}
            {!fetchedPosts &&
              <tr>
                <td colSpan="">Getting content from headway. This may take as long as 30 seconds.</td>
              </tr>
            }
            {!hasPosts && fetchedPosts &&
              <tr>
                <td colSpan="">No content from headway available.</td>
              </tr>
            }
          </tbody>
        </Table>
        {hasPagination &&
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="d-flex flex-row align-items-center justify-content-start">
              <small style={{width: "100px"}} className="mr-2 text-muted">Items {posts.paging.offset} - {posts.paging.nextOffset ? posts.paging.nextOffset : posts.paging.count}</small>
              <Form.Control
                style={{maxWidth: "100px"}}
                as="select"
                onChange={e => setLimit(e.target.value)}
                value={limit}
              >
                {[5,10,25,50,100,250,500,1000].map(v => {
                  return(
                    <option key={`po_${v}`}>{v}</option>
                  )
                })}
              </Form.Control>
            </div>
            <ButtonGroup>
              <Button
                variant="link"
                onClick={() => setOffset(posts.paging.nextOffset)}
              >
                <ChevronLeft />
              </Button>
              <Button
                variant="link"
                className="text-dark"
              >
                {posts.paging.currentPage} of {posts.paging.pages ? posts.paging.pages : posts.paging.currentPage}
              </Button>
              <Button
                variant="link"
                onClick={() => setOffset(posts.paging.nextOffset)}
              >
                <ChevronRight />
              </Button>
            </ButtonGroup>
          </div>
        }
      </React.Fragment>
  
    )
  }
  
  export default withBulkSelect(ImportHeadway);
  