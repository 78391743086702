export default {
    appLogo: "https://s.w.org/style/images/about/WordPress-logotype-standard.png",
    appName: "wordpress",
    data: {
      blogUrl: ""
    },
    importedContent: [],
    lastImport: 0,
    type: "import"
    // Added later on:
    // lastUpdated: Number secSinceEpoc,
    // _created: Number secSinceEpoc,
    // archived: Boolean 
  }
  