export default {
  appLogo: "https://d56vh6ph4jjmq.cloudfront.net/fb-main/logo/color/forumbee.svg",
  appName: "forumbee",
  data: {
    apiToken: "",
    forumUrl: ""
  },
  importedContent: [],
  lastImport: 0,
  lastWebhook: 0,
  type: "import"
  // Added later on:
  // lastUpdated: Number secSinceEpoc,
  // _created: Number secSinceEpoc,
  // archived: Boolean 
}
