import React, { useState, useEffect } from "react";
import { db } from "./firebase.jsx";
import {
  Form,
  Accordion,
  Card,
  Table,
  Button,
  ListGroup,
} from "react-bootstrap";

const fetchDuplicates = async (params, callback) => {
  console.log("Checking for duplicates.");
  const { currentSearch, userDoc, apiKey, apiUrl, dataDict } = params;
  //console.log("currentFilter: ", currentSearch.filter);
  try {
    function sumbb(bb) {
      let sum = 0;
      let param = bb.find((p) => Object.keys(p).includes("geo_bounding_box"));
      //console.log("param: ", param);
      let paramValues = param.geo_bounding_box[dataDict.map.location];
      //console.log("paramValues: ", paramValues);
      let array = [
        paramValues.top_left.lon,
        paramValues.top_left.lat,
        paramValues.bottom_right.lon,
        paramValues.bottom_right.lat,
      ];
      //console.log("array: ", array);
      array.map((n) => (sum += parseFloat(n.toFixed(2))));
      return sum;
    }

    let duplicateSum = 0;
    let maxOffset = 0;
    let fetchedDownloads = [];
    let offsets = [];
    let nonDupeCount;
    let downloadsRef = db
      .collection("downloads")
      .doc(userDoc.uid)
      .collection("files")
      .where("queryUrl.filter", "==", currentSearch.filter);
    let sameDownloads = await downloadsRef.get();
    sameDownloads.forEach((doc) => {
      let docData = doc.data();
      docData.id = doc.id;
      // Only show files where the database is the same
      if (docData.queryUrl.url !== apiUrl) {
        return null;
      }
      // Only show files where the dslFilters are the same
      // Check if the download is a map search
      if (docData.queryUrl.dslFilter) {
        // If the current search is not a mpa search, it can't be a duplicate
        if (!currentSearch.dslFilter) {
          return null;
        }
      }
      // Check if the current search is a mapsearch
      if (currentSearch.dslFilter) {
        // If the download isn't a map search, it can't be a duplicate
        if (!docData.queryUrl.dslFilter) {
          return null;
        }

        let bbMatch =
          sumbb(currentSearch.dslFilter) === sumbb(docData.queryUrl.dslFilter);
        if (!bbMatch) {
          return null;
        }
      }
      // Only show files where the download completed and it hasn't been reported
      if (docData.csvFile && !docData.reported) {
        fetchedDownloads.push(docData);
      }
      // check if they are actually duplicates
      if (!offsets.includes(docData.offset)) {
        offsets.push(docData.offset);
        duplicateSum += parseInt(docData.downloadCount);
      }
      // prepare for fetching a non-duplicate count
      if (maxOffset < docData.offset) {
        maxOffset = docData.offset;
      }
    });

    //console.log("fetchedDownloads: ", fetchedDownloads, "duplicateSum: ", duplicateSum, "offsets: ", offsets, "currentFilter: ", currentFilter, "this.apiUrl: ", this.apiUrl);
    if (maxOffset > 0) {
      let newSearch = { ...currentSearch };
      newSearch.offset = maxOffset;
      newSearch.count_only = "true";
      //console.log("newSearch: ", newSearch);
      let postBody = JSON.stringify(newSearch);
      // await response of fetch call
      const init = {
        method: "POST",
        headers: {
          authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json",
        },
        "Transfer-Encoding": "chunked",
        cache: "default",
        accept: "application/json",
        body: postBody,
      };
      try {
        let response = await fetch(apiUrl, init);
        nonDupeCount = await response.json();
      } catch (err) {
        console.log("Something went wrong fetching nonDupeCount: ", err);
      }
    }

    let sortedDownloads = fetchedDownloads.sort((a, b) => {
      let aV = a.timestamp.seconds;
      let bV = b.timestamp.seconds;
      return aV - bV;
    });

    let duplicateDownloads = {
      duplicateSum,
      maxOffset,
      nonDupeCount,
      items: sortedDownloads,
    };
    console.log("duplicateDownloads: ", duplicateDownloads);
    if (callback) {
      console.log("ran callback: ", callback);
      callback(null, duplicateDownloads);
    }
  } catch (err) {
    console.log("Error checking for duplicate downloads: ", err);
    if (callback) {
      callback(err);
    }
  }
};

const DisplayDuplicateDownloads = (props) => {
  const {
    user,
    currentSearch,
    userDoc,
    apiKey,
    apiUrl,
    dataDict,
    userplan,
    handleUpgrade,
    dupesCallback,
    suppressSearchCallback,
    tableVisible,
    allowToggle
  } = props;

  const [suppressSearch, setSuppressSearch] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [duplicateDownloads, setDuplicateDownloads] = useState(null);

  useEffect(() => {
    setSuppressSearch(userplan.searchSuppressions);
    fetchDuplicates(
      {
        currentSearch,
        userDoc,
        apiKey,
        apiUrl,
        dataDict,
      },
      (error, dd) => {
        if (error) {
          console.log("Error fetching duplicate downloads: ", error);
        }
        if (dd) {
          setDuplicateDownloads(dd);
          if (dd.items.length > 0) {
            setIsDuplicate(true);
          }
        }
        if (dupesCallback) {
          dupesCallback(error, dd);
        }
      }
    );
  }, []);

  if(!user) {
    return null
  }

  if (!isDuplicate) {
    return null;
  }

  console.log("duplicateDownloads: ", duplicateDownloads);
  const { duplicateSum, items, nonDupeCount } = duplicateDownloads;
  return (
    <Form className="mb-2">
      <Accordion className="mb-2 mt-2" defaultActiveKey={ tableVisible ? "0" : null}>
        <Accordion.Toggle
          as={Button}
          variant="link"
          eventKey="0"
          className="text-danger text-italic"
          style={{
            fontSize: "1.1em",
            fontStyle: "italic",
          }}
        >
          You've already downloaded some of these leads &nbsp;
          <i className="fa fa-angle-down fa-1x" aria-hidden="true"></i>
        </Accordion.Toggle>

        <Accordion.Collapse eventKey="0">
          <Card>
            <Card.Body>
              <Table size="sm" hover responsive>
                <thead>
                  <tr>
                    <th>Search name</th>
                    <th>Date</th>
                    <th>Download</th>
                    <th className="text-right">Download count</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((download, i) => {
                    if (download.csvFile) {
                      return (
                        <tr key={`tr_${i}`}>
                          <td>{download.searchName}</td>
                          <td>
                            {new Date(
                              download.timestamp.seconds * 1000
                            ).toLocaleString()}
                          </td>
                          <td>
                            <a
                              className="alert-link"
                              href={download.csvFile}
                              download={`${download.searchName
                                .replace(/\s/g, "_")
                                .replace(/\//g, "-")}.csv`}
                            >
                              csv
                            </a>
                          </td>
                          <td className="text-right">
                            {download.downloadCount}
                          </td>
                        </tr>
                      );
                    } else return null;
                  })}
                </tbody>
              </Table>
            </Card.Body>
            <ListGroup variant="flush">
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Leads already downloaded from this search:
                <div
                  style={{
                    fontSize: "1.2em",
                  }}
                >
                  {duplicateSum.toLocaleString()}
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Uniqe leads remaining:
                <div
                  style={{
                    fontSize: "1.2em",
                  }}
                >
                  {nonDupeCount.toLocaleString()}
                </div>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Accordion.Collapse>
      </Accordion>
      <div className="d-flex flex-row justify-content-start no-gutters">
        {allowToggle &&
          <div>
            <Form.Check
              checked={suppressSearch}
              onChange={(e) => {
                setSuppressSearch(!suppressSearch);
                if (suppressSearchCallback) {
                  suppressSearchCallback(!suppressSearch);
                }
              }}
              disabled={!userplan.searchSuppressions}
              name="suppressSearch"
              type="checkbox"
              label="Suppress duplicates"
            />
          </div>
        }
        {!userplan.searchSuppressions && (
          <div>
            <button
              type="button"
              onClick={handleUpgrade}
              className="badge badge-pill badge-info ml-1"
            >
              Upgrade
            </button>
          </div>
        )}
      </div>

      <Form.Row className="text-left">
        <Form.Text className="text-muted text-left">
          Search suppressions help prevent you from downloading the same leads
          more than once.
        </Form.Text>
        {!userplan.searchSuppressions && (
          <small className="text-muted text-left">
            Your account doesn't have access to this feature. To avoid
            duplicates, change your search or upgrade your account.
          </small>
        )}
      </Form.Row>
    </Form>
  );
};

export { fetchDuplicates, DisplayDuplicateDownloads };
