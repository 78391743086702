const digestPlans = {
  0: {
    shortname: "Free trial",
    description: "Create unlimited manual digests. Copy and paste to export.",
    name: "Digests Free trial",
    price: 0,
    planID: process.env.REACT_APP_plan_dig_free.toLowerCase(),
    integrations: 2,
    users: 0,
    public: true
  },
  1: {
    shortname: "Basic",
    description: "Send digests via email or connect to one of our support providers.",
    name: "Digests Basic",
    price: 5000,
    planID: process.env.REACT_APP_plan_dig_free.toLowerCase(),
    integrations: 5,
    users: 2,
    searchSuppressions: false,
    public: false
  },
  2: {
    shortname: "Pro",
    description: "Automatically import content from other sources",
    name: "Digests Pro",
    price: 1000,
    planID: process.env.REACT_APP_plan_dig_free.toLowerCase(),
    integrations: 10,
    users: 5,
    searchSuppressions: false,
    public: false
  },
  3: {
    shortname: "Business",
    description: "Add your whole team to Digests.",
    name: "Digests Business",
    price: 5000,
    planID: process.env.REACT_APP_plan_dig_free.toLowerCase(),
    integrations: 100,
    users: 15,
    searchSuppressions: false,
    public: false
  }
};

export default digestPlans;
