export default {
    appLogo: "https://gblobscdn.gitbook.com/spaces%2F-KtBFWwkuvmmjgL9DaNx%2Flogos%2Flogo.png?alt=media&token=3bf10876-8bf2-4979-b8a8-680f25ee5997",
    appName: "headway",
    data: {
      changelogUrl: ""
    },
    importedContent: [],
    lastImport: 0,
    type: "import"
    // Added later on:
    // lastUpdated: Number secSinceEpoc,
    // _created: Number secSinceEpoc,
    // archived: Boolean 
  }
  