import React, {useState, useEffect} from "react";
import {
  Table,
  Button,
  Image,
  Form,
  ButtonGroup
} from "react-bootstrap";
import {
  ArrowUpRightSquare,
  ChevronLeft,
  ChevronRight
} from "react-bootstrap-icons";
import {getFirstSentence, createArticle, htmlToPlain} from "./interfaceDigests.jsx";
import withBulkSelect from "./withBulkSelect.jsx";
import {db, firebase} from "./firebase.jsx";

const ImportWebflow = props => {
  console.log("ImportWebflow props: ", props);
  const {handleBulkSelectState, BulkHeader, SelectItem, selectedData, userDoc, user, integration, handleAlerts, fetchFromApi} = props;
  const [posts, setPosts] = useState([]);

  // Fetch parameters
  const [collections, setCollections] = useState(null);
  const [collection, setCollection] = useState(null);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(25);
  const [search, setSearch] = useState("");

  const fetchCollections = () => {
    fetchFromApi({
      body: {
        ...integration.data,
      },
      endpoint: "/api/webflow/collections"
    }, (error,cols) => {
      if (error) {
        console.log("Error getting forum bee stuff: ", error);
      }
      if (cols) {
        let allowedCols = integration.data.collections ? integration.data.collections.map( c => c.collectionName ) : cols;
        console.log("allowedCols: ", allowedCols);
        cols = cols.filter( c => allowedCols.includes(c.name)); 
        console.log("collections: ", cols);
        setCollections(cols)
        setCollection(cols[0])
      }
    });
  };

  const fetchItems = () => {
    fetchFromApi({
      body: {
        ...integration.data,
        collectionId: collection._id,
        limit,
        offset
      },
      endpoint: "/api/webflow/items"
    }, (error,posts) => {
      if (error) {
        console.log("Error getting forum bee stuff: ", error);
      }
      if (posts) {
        console.log("posts: ", posts);
        let collectionConfig = integration.data.collections.find( mapping => mapping.collectionName === collection.name);
        setPosts( posts.items.filter( p => p._draft === false).map( post => parsePost({post, integration, collectionConfig})) )
      }
    });
  }

  const parsePost = ({post, integration, collectionConfig}) => {
    let imgUrl = post[collectionConfig.image] ? [post[collectionConfig.image]["url"]] : [];
    let content = post[collectionConfig.content];
    let contentType = "html"
    let preview = getFirstSentence(post[collectionConfig.preview] ? htmlToPlain(post[collectionConfig.preview].trim() ) : content ? htmlToPlain(content) : "No preview :(");
    let title = post[collectionConfig.title] ? post[collectionConfig.title].trim() : "No content :(";
    let _created = Math.round(Date.parse(post["created-on"])/1000);
    let link = `${integration.data.siteUrl}/${collection.slug}/${post[collectionConfig.link]}`;
    let externalId = post._id;
    let nd = {
      imgUrl, 
      title, 
      preview, 
      content, 
      link, 
      _created, 
      externalId, 
      contentType
    };
    //console.log("nd: ", nd);
    return nd;
  }

  useEffect( () => {
    if (collections && collection) {
      fetchItems();
    } else {
      fetchCollections();
    }
    
  }, [collection]);

  let hasPosts = posts ? posts.length > 0 ? true : false : false;
  let hasPagination = posts ? posts.length === limit ? true : false : false;
  if (!collections || !collection) {
    return null;
  }
  
  return(
    <React.Fragment>
      {selectedData.length > 0 &&
        <div className="d-flex flex-row justify-content-end">
          <Button
            onClick={() => {
              let bulkData = selectedData.map( id => posts.find( p => p.externalId === id) ).filter( nd => typeof nd !== "undefined");
              console.log("bulkdData: ", bulkData);
							//const { hostname } = new URL(blogUrl);
              createArticle({
                bulkData,
                aid: userDoc.id,
                uid: user.uid,
                category: ["webflow"]
              }, (error, summary) => {
                if (error) {
                  console.log("Error importing forumbee content: ", error);
                }
                if (summary) {
                  console.log("Successfully imported forumbee content: ", summary);
                  // update the integration with the ids of the articles that were imported
                  let allIds = [...integration.importedContent, ...selectedData];
                  let uniqueIds = [...new Set(allIds)];
                  integration._docRef.update({importedContent: uniqueIds});
                  db.collection("digests").doc(userDoc.id).update({
                    categories: firebase.firestore.FieldValue.arrayUnion("forumbee")
                  });
                  handleAlerts("", `Yay, you imported ${selectedData.length} new items!`, "success");
                  console.log("Successfully updated integration document: ", uniqueIds);
                  handleBulkSelectState({selectedData: []});
                }
              })
            }}
          >
            Import {selectedData.length} Items
          </Button>
        </div>
      }
      <Form.Label>Select Content Type</Form.Label>
      <div className="d-flex flex-row justify-content-start flex-wrap mb-2">
        {collections.map( col => {
          //console.log("col: ", col);
          let {name, _id} = col;
          return(
            <Button
              key={`ptbtn_${_id}`}
              className="m-1"
              size="sm"
              variant={collection === col ? "secondary" : "outline-secondary"}
              onClick={() => setCollection(col)}
            >
              {name}
            </Button>
          )
        })}
      </div>
      <Table responsive>
        <thead>
          <tr>
            <th>
              <BulkHeader
                data={posts}
                idField="externalId"
                totalItems={ null }
              />
            </th>
            {hasPosts && 
              ["Image", "Post", "Created"].map(th => {
                return(
                  <th key={`th_${th}`}>{th}</th>
                )
              })
            }
          </tr>
        </thead>
        <tbody>
          {hasPosts && posts.map( (post,i) => {
            //console.log("post: ", post);
            let {imgUrl, title, preview, link, _created, externalId} = post;
            return(
              <tr key={`p_${i}`}>
                <td>
                  <SelectItem
                    id={externalId}
                    className={integration.importedContent.includes(externalId) ? "text-warning" : "text-primary"}
                  />
                </td>
                <td>
                  {imgUrl.length > 0 && 
                    <div style={{width: "50px", height: "50px"}}>
                      <Image thumbnail
                        src={ imgUrl[0] }
                        style={{
                          width: "50px",
                          height: "50px",
                          objectFit: "cover"
                        }}
                      />
                    </div>
                  }
                  {/*post.hasMedia && post.media.data[0].mediaType === 2 &&
                    <div dangerouslySetInnerHTML={{__html: post.media.data[0].mediaUrl}} />
                  */}
                  {!imgUrl.length && <span>&nbsp;</span>}
                </td>
                <td>
                  <span dangerouslySetInnerHTML={{__html: title}} />&nbsp;<a target="_blank" rel="noopener noreferrer" href={`${link}`}><ArrowUpRightSquare /></a><br />
                  <small className="text-italic" dangerouslySetInnerHTML={{__html: preview}} />
                </td>
                <td style={{minWidth: "120px", fontSize: ".8rem"}}>{new Date(_created * 1000).toLocaleString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric"
                  })}</td>
              </tr>
            )
          })}
          {!hasPosts &&
            <tr>
              <td colSpan="">No content from webflow available matching this criteria.</td>
            </tr>
          }
        </tbody>
      </Table>
      {hasPagination &&
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex flex-row align-items-center justify-content-start">
            <small style={{width: "100px"}} className="mr-2 text-muted">Items {offset + 1} - {offset + limit}</small>
            <Form.Control
              style={{maxWidth: "100px"}}
              as="select"
              onChange={e => setLimit(e.target.value)}
              value={limit}
            >
              {[5,10,25,50,100,250,500,1000].map(v => {
                return(
                  <option key={`po_${v}`}>{v}</option>
                )
              })}
            </Form.Control>
          </div>
          <ButtonGroup>
            <Button
              variant="link"
              onClick={() => setOffset(offset - limit)}
							disabeld={ offset-limit <= 0}
            >
              <ChevronLeft />
            </Button>
            <Button
              variant="link"
              className="text-dark"
            >
              {offset + 1} to {offset + limit}
            </Button>
            <Button
              variant="link"
              onClick={() => setOffset(offset + limit)}
            >
              <ChevronRight />
            </Button>
          </ButtonGroup>
        </div>
      }
    </React.Fragment>

  )
}

export default withBulkSelect(ImportWebflow);
